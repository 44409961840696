var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"dkzc-box"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"left"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/index' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("广告详情")])],1),_c('onshare'),_c('div',{staticClass:"content"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.journalism.info.title))]),_c('span',{staticClass:"subTitle"},[_vm._v(_vm._s(_vm.journalism.info.subtitle)+" ")]),(_vm.journalism.info.video && _vm.journalism.info.type == 2)?_c('video',{staticClass:"video",staticStyle:{"height":"300px"},attrs:{"src":_vm.journalism.info.video,"controls":"","muted":"","autoplay":""},domProps:{"muted":true}}):_vm._e(),_c('div',{staticClass:"vhtml",domProps:{"innerHTML":_vm._s(_vm.journalism.info.content)}}),_c('span',{staticClass:"contentTime"},[_vm._v(" "+_vm._s(_vm._f("timeFormat")(_vm.journalism.info.create_time))+" ")]),_c('span',{staticClass:"contentPrompt"},[_vm._v(" 内容来自于: "+_vm._s(_vm.journalism.info.business)+" ")])]),_c('div',{staticClass:"icons"},[(_vm.is_collect == 1)?_c('div',{staticClass:"favoritesNumber"},[_c('i',{staticClass:"iconfont",class:{
              shoucanghover: _vm.journalism.info.collect_status,
              shoucang: !_vm.journalism.info.collect_status,
            },on:{"click":_vm.collect}}),_c('span',[_vm._v(_vm._s(_vm.journalism.info.collec_number))])]):_vm._e(),(_vm.is_zan == 1)?_c('div',{staticClass:"like"},[_c('i',{staticClass:"iconfont",class:{
              zan1: _vm.journalism.info.praise_status,
              iconzan2: !_vm.journalism.info.praise_status,
            },on:{"click":_vm.give}}),_c('span',[_vm._v(_vm._s(_vm.journalism.info.praise_number))])]):_vm._e(),(_vm.is_share)?_c('span',{staticClass:"share is_share_show"},[_vm._v("分享：")]):_vm._e(),(_vm.is_share)?_c('span',[_vm._v(_vm._s(_vm.journalism.info.share_number))]):_vm._e(),(_vm.is_share)?_c('div',{on:{"click":_vm.findShare}},[_c('sharee',{staticClass:"is_share_show",attrs:{"config":_vm.config}})],1):_vm._e()])],1),_c('div',{staticClass:"right-group"},_vm._l((_vm.newsHotList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < 4),expression:"index < 4"}],key:index,staticClass:"group",on:{"click":function($event){return _vm.navtoInfo(item.id)}}},[(item.images)?_c('img',{staticClass:"information",attrs:{"src":item.images}}):_vm._e(),_c('div',{staticClass:"right"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("ct")(item.info_count))+"次浏览")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }